/* eslint-disable import/no-anonymous-default-export */
import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    control: {
      bg: "gray.50",
      borderWidth: 0,
      rounded: "sm",
      _checked: {
        borderWidth: 0,
        bg: "gray.50",
        color: "main.blue",
        fontWeight: "black",
        _hover: {
          bg: "gray.50",
          borderColor: "gray.50",
        },
      },
    },
  };
};

const variantChoose: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      gap: 4,
    },
    icon: {
      fontSize: 12,
    },
    control: {
      borderColor: "main.blue",
      borderWidth: 1,
      width: 8,
      height: 8,
      rounded: "sm",
      _checked: {
        bg: "main.blue",
        borderColor: "main.blue",
        color: "gray.100",
        _hover: {
          bg: "main.blue",
          borderColor: "main.blue",
        },
      },
    },
  };
};

const varianTableSelect: PartsStyleFunction<typeof parts> = (props) => {
  return {
    control: {
      borderWidth: 2,
      borderColor: "main.blue",
      rounded: "sm",
      _checked: {
        borderWidth: 0,
        bg: "main.blue",
        color: "gray.50",
      },
      _indeterminate: {
        borderWidth: 0,
        bg: "main.blue",
        color: "gray.50",
      },
    },
  };
};

const variants = {
  main: variantMain,
  choose: variantChoose,
  tableSelect: varianTableSelect,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
