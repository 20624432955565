/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const parameterStore = /* GraphQL */ `query ParameterStore($key: String!) {
  parameterStore(key: $key) {
    key
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ParameterStoreQueryVariables,
  APITypes.ParameterStoreQuery
>;
export const checkItemPreOrder = /* GraphQL */ `query CheckItemPreOrder($itemId: String!) {
  checkItemPreOrder(itemId: $itemId) {
    alreadyOrdered
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckItemPreOrderQueryVariables,
  APITypes.CheckItemPreOrderQuery
>;
export const listRandomAvailableItem = /* GraphQL */ `query ListRandomAvailableItem($noReturn: Int!) {
  listRandomAvailableItem(noReturn: $noReturn) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRandomAvailableItemQueryVariables,
  APITypes.ListRandomAvailableItemQuery
>;
export const listItemAvailablePreOrder = /* GraphQL */ `query ListItemAvailablePreOrder {
  listItemAvailablePreOrder {
    items
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListItemAvailablePreOrderQueryVariables,
  APITypes.ListItemAvailablePreOrderQuery
>;
export const calculateCollectionSize = /* GraphQL */ `query CalculateCollectionSize($collectionId: String!, $type: String!) {
  calculateCollectionSize(collectionId: $collectionId, type: $type) {
    totalFile
    size
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CalculateCollectionSizeQueryVariables,
  APITypes.CalculateCollectionSizeQuery
>;
export const getAllItemS3 = /* GraphQL */ `query GetAllItemS3($prefix: String) {
  getAllItemS3(prefix: $prefix) {
    s3Items {
      url
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllItemS3QueryVariables,
  APITypes.GetAllItemS3Query
>;
export const getCandyMachineInfoByCollectionId = /* GraphQL */ `query GetCandyMachineInfoByCollectionId($collectionId: String) {
  getCandyMachineInfoByCollectionId(collectionId: $collectionId) {
    itemsAvailable
    itemsLoaded
    itemsMinted
    items {
      name
      uri
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCandyMachineInfoByCollectionIdQueryVariables,
  APITypes.GetCandyMachineInfoByCollectionIdQuery
>;
export const getCandyMachineInfoV2 = /* GraphQL */ `query GetCandyMachineInfoV2($collectionId: String!) {
  getCandyMachineInfoV2(collectionId: $collectionId) {
    itemsAvailable
    itemsLoaded
    itemsMinted
    mintingType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCandyMachineInfoV2QueryVariables,
  APITypes.GetCandyMachineInfoV2Query
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($id: ID!) {
  getUserProfile(id: $id) {
    id
    name
    email
    profilePictureUrl
    profileBannerUrl
    description
    type
    collections {
      items {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      nextToken
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    wallets {
      items {
        id
        userId
        currency
        publicKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    whitelistUser {
      items {
        id
        userId
        whitelistId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    executeNFT {
      items {
        id
        orderId
        quantity
        userId
        collectionId
        ttl
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles(
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
export const userProfileByName = /* GraphQL */ `query UserProfileByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  userProfileByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserProfileByNameQueryVariables,
  APITypes.UserProfileByNameQuery
>;
export const userProfileByEmail = /* GraphQL */ `query UserProfileByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  userProfileByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserProfileByEmailQueryVariables,
  APITypes.UserProfileByEmailQuery
>;
export const userProfileByType = /* GraphQL */ `query UserProfileByType(
  $type: UserType!
  $sortDirection: ModelSortDirection
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  userProfileByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserProfileByTypeQueryVariables,
  APITypes.UserProfileByTypeQuery
>;
export const searchUserProfiles = /* GraphQL */ `query SearchUserProfiles(
  $filter: SearchableUserProfileFilterInput
  $sort: [SearchableUserProfileSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserProfileAggregationInput]
) {
  searchUserProfiles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUserProfilesQueryVariables,
  APITypes.SearchUserProfilesQuery
>;
export const getWhitelist = /* GraphQL */ `query GetWhitelist($id: ID!) {
  getWhitelist(id: $id) {
    id
    startDate
    endDate
    expiredAt
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWhitelistQueryVariables,
  APITypes.GetWhitelistQuery
>;
export const listWhitelists = /* GraphQL */ `query ListWhitelists(
  $filter: ModelWhitelistFilterInput
  $limit: Int
  $nextToken: String
) {
  listWhitelists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      expiredAt
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhitelistsQueryVariables,
  APITypes.ListWhitelistsQuery
>;
export const getWhitelistUser = /* GraphQL */ `query GetWhitelistUser($id: ID!) {
  getWhitelistUser(id: $id) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    whitelistId
    price {
      value
      currency
      __typename
    }
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWhitelistUserQueryVariables,
  APITypes.GetWhitelistUserQuery
>;
export const listWhitelistUsers = /* GraphQL */ `query ListWhitelistUsers(
  $filter: ModelWhitelistUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listWhitelistUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      whitelistId
      price {
        value
        currency
        __typename
      }
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhitelistUsersQueryVariables,
  APITypes.ListWhitelistUsersQuery
>;
export const whitelistUserByUserId = /* GraphQL */ `query WhitelistUserByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWhitelistUserFilterInput
  $limit: Int
  $nextToken: String
) {
  whitelistUserByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      whitelistId
      price {
        value
        currency
        __typename
      }
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WhitelistUserByUserIdQueryVariables,
  APITypes.WhitelistUserByUserIdQuery
>;
export const whitelistUserByWhitelistId = /* GraphQL */ `query WhitelistUserByWhitelistId(
  $whitelistId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWhitelistUserFilterInput
  $limit: Int
  $nextToken: String
) {
  whitelistUserByWhitelistId(
    whitelistId: $whitelistId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      whitelistId
      price {
        value
        currency
        __typename
      }
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WhitelistUserByWhitelistIdQueryVariables,
  APITypes.WhitelistUserByWhitelistIdQuery
>;
export const searchWhitelistUsers = /* GraphQL */ `query SearchWhitelistUsers(
  $filter: SearchableWhitelistUserFilterInput
  $sort: [SearchableWhitelistUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableWhitelistUserAggregationInput]
) {
  searchWhitelistUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      whitelistId
      price {
        value
        currency
        __typename
      }
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchWhitelistUsersQueryVariables,
  APITypes.SearchWhitelistUsersQuery
>;
export const getCollection = /* GraphQL */ `query GetCollection($id: ID!) {
  getCollection(id: $id) {
    id
    slug
    name
    description
    thumbnailUrl
    bannerUrl
    metadataUrl
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    items {
      items {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    address
    status
    startDate
    endDate
    totalItems
    price {
      value
      currency
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionQueryVariables,
  APITypes.GetCollectionQuery
>;
export const listCollections = /* GraphQL */ `query ListCollections(
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionsQueryVariables,
  APITypes.ListCollectionsQuery
>;
export const collectionBySlug = /* GraphQL */ `query CollectionBySlug(
  $slug: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  collectionBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CollectionBySlugQueryVariables,
  APITypes.CollectionBySlugQuery
>;
export const collectionByCreator = /* GraphQL */ `query CollectionByCreator(
  $creatorId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  collectionByCreator(
    creatorId: $creatorId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CollectionByCreatorQueryVariables,
  APITypes.CollectionByCreatorQuery
>;
export const collectionByStatus = /* GraphQL */ `query CollectionByStatus(
  $status: CollectionStatus!
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionFilterInput
  $limit: Int
  $nextToken: String
) {
  collectionByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CollectionByStatusQueryVariables,
  APITypes.CollectionByStatusQuery
>;
export const searchCollections = /* GraphQL */ `query SearchCollections(
  $filter: SearchableCollectionFilterInput
  $sort: [SearchableCollectionSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCollectionAggregationInput]
) {
  searchCollections(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCollectionsQueryVariables,
  APITypes.SearchCollectionsQuery
>;
export const getExecuteNFT = /* GraphQL */ `query GetExecuteNFT($id: ID!) {
  getExecuteNFT(id: $id) {
    id
    orderId
    quantity
    price {
      value
      currency
      __typename
    }
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExecuteNFTQueryVariables,
  APITypes.GetExecuteNFTQuery
>;
export const listExecuteNFTS = /* GraphQL */ `query ListExecuteNFTS(
  $filter: ModelExecuteNFTFilterInput
  $limit: Int
  $nextToken: String
) {
  listExecuteNFTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderId
      quantity
      price {
        value
        currency
        __typename
      }
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExecuteNFTSQueryVariables,
  APITypes.ListExecuteNFTSQuery
>;
export const executeNFTByOrderId = /* GraphQL */ `query ExecuteNFTByOrderId(
  $orderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExecuteNFTFilterInput
  $limit: Int
  $nextToken: String
) {
  executeNFTByOrderId(
    orderId: $orderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      quantity
      price {
        value
        currency
        __typename
      }
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExecuteNFTByOrderIdQueryVariables,
  APITypes.ExecuteNFTByOrderIdQuery
>;
export const executeNFTByUserId = /* GraphQL */ `query ExecuteNFTByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExecuteNFTFilterInput
  $limit: Int
  $nextToken: String
) {
  executeNFTByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      quantity
      price {
        value
        currency
        __typename
      }
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExecuteNFTByUserIdQueryVariables,
  APITypes.ExecuteNFTByUserIdQuery
>;
export const executeNFTByCollection = /* GraphQL */ `query ExecuteNFTByCollection(
  $collectionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelExecuteNFTFilterInput
  $limit: Int
  $nextToken: String
) {
  executeNFTByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      quantity
      price {
        value
        currency
        __typename
      }
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExecuteNFTByCollectionQueryVariables,
  APITypes.ExecuteNFTByCollectionQuery
>;
export const searchExecuteNFTS = /* GraphQL */ `query SearchExecuteNFTS(
  $filter: SearchableExecuteNFTFilterInput
  $sort: [SearchableExecuteNFTSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableExecuteNFTAggregationInput]
) {
  searchExecuteNFTS(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      orderId
      quantity
      price {
        value
        currency
        __typename
      }
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchExecuteNFTSQueryVariables,
  APITypes.SearchExecuteNFTSQuery
>;
export const getItem = /* GraphQL */ `query GetItem($id: ID!) {
  getItem(id: $id) {
    id
    name
    description
    imageUrl
    metadataUrl
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    creatorId
    creator {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    collectionId
    collection {
      id
      slug
      name
      description
      thumbnailUrl
      bannerUrl
      metadataUrl
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      address
      status
      startDate
      endDate
      totalItems
      price {
        value
        currency
        __typename
      }
      __typename
    }
    tradeStateAddress
    recipientAddress
    address
    price {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    holdUserId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetItemQueryVariables, APITypes.GetItemQuery>;
export const listItems = /* GraphQL */ `query ListItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListItemsQueryVariables, APITypes.ListItemsQuery>;
export const itemByOwner = /* GraphQL */ `query ItemByOwner(
  $ownerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByOwner(
    ownerId: $ownerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByOwnerQueryVariables,
  APITypes.ItemByOwnerQuery
>;
export const itemByCreator = /* GraphQL */ `query ItemByCreator(
  $creatorId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByCreator(
    creatorId: $creatorId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByCreatorQueryVariables,
  APITypes.ItemByCreatorQuery
>;
export const itemByCollection = /* GraphQL */ `query ItemByCollection(
  $collectionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByCollectionQueryVariables,
  APITypes.ItemByCollectionQuery
>;
export const itemByCollectionAndHoldUserId = /* GraphQL */ `query ItemByCollectionAndHoldUserId(
  $collectionId: ID!
  $holdUserId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByCollectionAndHoldUserId(
    collectionId: $collectionId
    holdUserId: $holdUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByCollectionAndHoldUserIdQueryVariables,
  APITypes.ItemByCollectionAndHoldUserIdQuery
>;
export const itemByAddress = /* GraphQL */ `query ItemByAddress(
  $address: String!
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByAddress(
    address: $address
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByAddressQueryVariables,
  APITypes.ItemByAddressQuery
>;
export const itemByHoldUserId = /* GraphQL */ `query ItemByHoldUserId(
  $holdUserId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  itemByHoldUserId(
    holdUserId: $holdUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemByHoldUserIdQueryVariables,
  APITypes.ItemByHoldUserIdQuery
>;
export const searchItems = /* GraphQL */ `query SearchItems(
  $filter: SearchableItemFilterInput
  $sort: [SearchableItemSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableItemAggregationInput]
) {
  searchItems(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchItemsQueryVariables,
  APITypes.SearchItemsQuery
>;
export const getSell = /* GraphQL */ `query GetSell($id: ID!) {
  getSell(id: $id) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    status
    price {
      value
      currency
      __typename
    }
    reservedFor
    sellDuration {
      startDate
      endDate
      __typename
    }
    auctionMethod
    sellingTypes
    currency
    reservedPrice {
      value
      currency
      __typename
    }
    endingPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSellQueryVariables, APITypes.GetSellQuery>;
export const listSells = /* GraphQL */ `query ListSells(
  $filter: ModelSellFilterInput
  $limit: Int
  $nextToken: String
) {
  listSells(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSellsQueryVariables, APITypes.ListSellsQuery>;
export const sellByItem = /* GraphQL */ `query SellByItem(
  $itemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSellFilterInput
  $limit: Int
  $nextToken: String
) {
  sellByItem(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellByItemQueryVariables,
  APITypes.SellByItemQuery
>;
export const sellByItemByStatus = /* GraphQL */ `query SellByItemByStatus(
  $itemId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSellFilterInput
  $limit: Int
  $nextToken: String
) {
  sellByItemByStatus(
    itemId: $itemId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellByItemByStatusQueryVariables,
  APITypes.SellByItemByStatusQuery
>;
export const sellByOwner = /* GraphQL */ `query SellByOwner(
  $ownerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSellFilterInput
  $limit: Int
  $nextToken: String
) {
  sellByOwner(
    ownerId: $ownerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellByOwnerQueryVariables,
  APITypes.SellByOwnerQuery
>;
export const searchSells = /* GraphQL */ `query SearchSells(
  $filter: SearchableSellFilterInput
  $sort: [SearchableSellSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableSellAggregationInput]
) {
  searchSells(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSellsQueryVariables,
  APITypes.SearchSellsQuery
>;
export const getSellDuration = /* GraphQL */ `query GetSellDuration($id: ID!) {
  getSellDuration(id: $id) {
    id
    itemId
    sellId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSellDurationQueryVariables,
  APITypes.GetSellDurationQuery
>;
export const listSellDurations = /* GraphQL */ `query ListSellDurations(
  $filter: ModelSellDurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSellDurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      itemId
      sellId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSellDurationsQueryVariables,
  APITypes.ListSellDurationsQuery
>;
export const sellDurationByItemId = /* GraphQL */ `query SellDurationByItemId(
  $itemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSellDurationFilterInput
  $limit: Int
  $nextToken: String
) {
  sellDurationByItemId(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      sellId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellDurationByItemIdQueryVariables,
  APITypes.SellDurationByItemIdQuery
>;
export const sellDurationBySellId = /* GraphQL */ `query SellDurationBySellId(
  $sellId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSellDurationFilterInput
  $limit: Int
  $nextToken: String
) {
  sellDurationBySellId(
    sellId: $sellId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      sellId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellDurationBySellIdQueryVariables,
  APITypes.SellDurationBySellIdQuery
>;
export const getPurchase = /* GraphQL */ `query GetPurchase($id: ID!) {
  getPurchase(id: $id) {
    id
    orderId
    userId
    itemId
    paymentGatewayName
    bank
    amount
    status
    virtualAccount
    checkoutUrl {
      name
      method
      url
      __typename
    }
    redirectUrl
    metaPaymentNotification
    metaRequestPayment
    metaResponsePayment
    expiredAt
    executeNFTId
    createdAt
    updatedAt
    collectionId
    numberOfMintingId
    action
    metaListing
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPurchaseQueryVariables,
  APITypes.GetPurchaseQuery
>;
export const listPurchases = /* GraphQL */ `query ListPurchases(
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPurchasesQueryVariables,
  APITypes.ListPurchasesQuery
>;
export const purchaseByOrderId = /* GraphQL */ `query PurchaseByOrderId(
  $orderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByOrderId(
    orderId: $orderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByOrderIdQueryVariables,
  APITypes.PurchaseByOrderIdQuery
>;
export const purchaseByUserId = /* GraphQL */ `query PurchaseByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByUserIdQueryVariables,
  APITypes.PurchaseByUserIdQuery
>;
export const purchaseByItemId = /* GraphQL */ `query PurchaseByItemId(
  $itemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByItemId(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByItemIdQueryVariables,
  APITypes.PurchaseByItemIdQuery
>;
export const purchaseByItemIdByExpiredAt = /* GraphQL */ `query PurchaseByItemIdByExpiredAt(
  $itemId: ID!
  $expiredAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByItemIdByExpiredAt(
    itemId: $itemId
    expiredAt: $expiredAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByItemIdByExpiredAtQueryVariables,
  APITypes.PurchaseByItemIdByExpiredAtQuery
>;
export const purchaseByItemIdByStatus = /* GraphQL */ `query PurchaseByItemIdByStatus(
  $itemId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByItemIdByStatus(
    itemId: $itemId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByItemIdByStatusQueryVariables,
  APITypes.PurchaseByItemIdByStatusQuery
>;
export const purchaseByPaymentGateway = /* GraphQL */ `query PurchaseByPaymentGateway(
  $paymentGatewayName: PurchasePaymentGatewayName!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByPaymentGateway(
    paymentGatewayName: $paymentGatewayName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByPaymentGatewayQueryVariables,
  APITypes.PurchaseByPaymentGatewayQuery
>;
export const purchaseByCollectionId = /* GraphQL */ `query PurchaseByCollectionId(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseByCollectionId(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      userId
      itemId
      paymentGatewayName
      bank
      amount
      status
      virtualAccount
      checkoutUrl {
        name
        method
        url
        __typename
      }
      redirectUrl
      metaPaymentNotification
      metaRequestPayment
      metaResponsePayment
      expiredAt
      executeNFTId
      createdAt
      updatedAt
      collectionId
      numberOfMintingId
      action
      metaListing
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseByCollectionIdQueryVariables,
  APITypes.PurchaseByCollectionIdQuery
>;
export const getPurchaseHold = /* GraphQL */ `query GetPurchaseHold($id: ID!) {
  getPurchaseHold(id: $id) {
    id
    orderId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPurchaseHoldQueryVariables,
  APITypes.GetPurchaseHoldQuery
>;
export const listPurchaseHolds = /* GraphQL */ `query ListPurchaseHolds(
  $filter: ModelPurchaseHoldFilterInput
  $limit: Int
  $nextToken: String
) {
  listPurchaseHolds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPurchaseHoldsQueryVariables,
  APITypes.ListPurchaseHoldsQuery
>;
export const purchaseHoldByOrderId = /* GraphQL */ `query PurchaseHoldByOrderId(
  $orderId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPurchaseHoldFilterInput
  $limit: Int
  $nextToken: String
) {
  purchaseHoldByOrderId(
    orderId: $orderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PurchaseHoldByOrderIdQueryVariables,
  APITypes.PurchaseHoldByOrderIdQuery
>;
export const getWallet = /* GraphQL */ `query GetWallet($id: ID!) {
  getWallet(id: $id) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    currency
    publicKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetWalletQueryVariables, APITypes.GetWalletQuery>;
export const listWallets = /* GraphQL */ `query ListWallets(
  $filter: ModelWalletFilterInput
  $limit: Int
  $nextToken: String
) {
  listWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      currency
      publicKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWalletsQueryVariables,
  APITypes.ListWalletsQuery
>;
export const walletByUser = /* GraphQL */ `query WalletByUser(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWalletFilterInput
  $limit: Int
  $nextToken: String
) {
  walletByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      currency
      publicKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WalletByUserQueryVariables,
  APITypes.WalletByUserQuery
>;
export const walletByPublicKey = /* GraphQL */ `query WalletByPublicKey(
  $publicKey: String!
  $sortDirection: ModelSortDirection
  $filter: ModelWalletFilterInput
  $limit: Int
  $nextToken: String
) {
  walletByPublicKey(
    publicKey: $publicKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      currency
      publicKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WalletByPublicKeyQueryVariables,
  APITypes.WalletByPublicKeyQuery
>;
export const searchWallets = /* GraphQL */ `query SearchWallets(
  $filter: SearchableWalletFilterInput
  $sort: [SearchableWalletSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableWalletAggregationInput]
) {
  searchWallets(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      currency
      publicKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchWalletsQueryVariables,
  APITypes.SearchWalletsQuery
>;
export const getBidHistory = /* GraphQL */ `query GetBidHistory($id: ID!) {
  getBidHistory(id: $id) {
    id
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    sellId
    sell {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      status
      price {
        value
        currency
        __typename
      }
      reservedFor
      sellDuration {
        startDate
        endDate
        __typename
      }
      auctionMethod
      sellingTypes
      currency
      reservedPrice {
        value
        currency
        __typename
      }
      endingPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    auctionMethod
    status
    currency
    bidPrice {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBidHistoryQueryVariables,
  APITypes.GetBidHistoryQuery
>;
export const listBidHistories = /* GraphQL */ `query ListBidHistories(
  $filter: ModelBidHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBidHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      sellId
      sell {
        id
        itemId
        ownerId
        status
        reservedFor
        auctionMethod
        sellingTypes
        currency
        createdAt
        updatedAt
        __typename
      }
      auctionMethod
      status
      currency
      bidPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBidHistoriesQueryVariables,
  APITypes.ListBidHistoriesQuery
>;
export const bidByItem = /* GraphQL */ `query BidByItem(
  $itemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBidHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  bidByItem(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      sellId
      sell {
        id
        itemId
        ownerId
        status
        reservedFor
        auctionMethod
        sellingTypes
        currency
        createdAt
        updatedAt
        __typename
      }
      auctionMethod
      status
      currency
      bidPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.BidByItemQueryVariables, APITypes.BidByItemQuery>;
export const bidBySell = /* GraphQL */ `query BidBySell(
  $sellId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBidHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  bidBySell(
    sellId: $sellId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      sellId
      sell {
        id
        itemId
        ownerId
        status
        reservedFor
        auctionMethod
        sellingTypes
        currency
        createdAt
        updatedAt
        __typename
      }
      auctionMethod
      status
      currency
      bidPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.BidBySellQueryVariables, APITypes.BidBySellQuery>;
export const searchBidHistories = /* GraphQL */ `query SearchBidHistories(
  $filter: SearchableBidHistoryFilterInput
  $sort: [SearchableBidHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBidHistoryAggregationInput]
) {
  searchBidHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      sellId
      sell {
        id
        itemId
        ownerId
        status
        reservedFor
        auctionMethod
        sellingTypes
        currency
        createdAt
        updatedAt
        __typename
      }
      auctionMethod
      status
      currency
      bidPrice {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBidHistoriesQueryVariables,
  APITypes.SearchBidHistoriesQuery
>;
export const getOffer = /* GraphQL */ `query GetOffer($id: ID!) {
  getOffer(id: $id) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    itemId
    item {
      id
      name
      description
      imageUrl
      metadataUrl
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      creatorId
      creator {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      collectionId
      collection {
        id
        slug
        name
        description
        thumbnailUrl
        bannerUrl
        metadataUrl
        creatorId
        createdAt
        updatedAt
        address
        status
        startDate
        endDate
        totalItems
        __typename
      }
      tradeStateAddress
      recipientAddress
      address
      price {
        value
        currency
        __typename
      }
      createdAt
      updatedAt
      holdUserId
      __typename
    }
    ownerId
    owner {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    price {
      value
      currency
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    currency
    quantity
    duration {
      startDate
      endDate
      __typename
    }
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOfferQueryVariables, APITypes.GetOfferQuery>;
export const listOffers = /* GraphQL */ `query ListOffers(
  $filter: ModelOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      price {
        value
        currency
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      currency
      quantity
      duration {
        startDate
        endDate
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOffersQueryVariables,
  APITypes.ListOffersQuery
>;
export const offerByUser = /* GraphQL */ `query OfferByUser(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  offerByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      price {
        value
        currency
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      currency
      quantity
      duration {
        startDate
        endDate
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OfferByUserQueryVariables,
  APITypes.OfferByUserQuery
>;
export const offerByItemId = /* GraphQL */ `query OfferByItemId(
  $itemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  offerByItemId(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      price {
        value
        currency
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      currency
      quantity
      duration {
        startDate
        endDate
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OfferByItemIdQueryVariables,
  APITypes.OfferByItemIdQuery
>;
export const offerByOwner = /* GraphQL */ `query OfferByOwner(
  $ownerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  offerByOwner(
    ownerId: $ownerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      itemId
      item {
        id
        name
        description
        imageUrl
        metadataUrl
        ownerId
        creatorId
        collectionId
        tradeStateAddress
        recipientAddress
        address
        createdAt
        updatedAt
        holdUserId
        __typename
      }
      ownerId
      owner {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      price {
        value
        currency
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      currency
      quantity
      duration {
        startDate
        endDate
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OfferByOwnerQueryVariables,
  APITypes.OfferByOwnerQuery
>;
export const getUserBalance = /* GraphQL */ `query GetUserBalance($id: ID!) {
  getUserBalance(id: $id) {
    id
    userId
    user {
      id
      name
      email
      profilePictureUrl
      profileBannerUrl
      description
      type
      collections {
        nextToken
        __typename
      }
      items {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      wallets {
        nextToken
        __typename
      }
      whitelistUser {
        nextToken
        __typename
      }
      executeNFT {
        nextToken
        __typename
      }
      __typename
    }
    deposit {
      value
      currency
      __typename
    }
    balance {
      value
      currency
      __typename
    }
    currency
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBalanceQueryVariables,
  APITypes.GetUserBalanceQuery
>;
export const listUserBalances = /* GraphQL */ `query ListUserBalances(
  $filter: ModelUserBalanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserBalances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      balance {
        value
        currency
        __typename
      }
      currency
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserBalancesQueryVariables,
  APITypes.ListUserBalancesQuery
>;
export const balanceByUserId = /* GraphQL */ `query BalanceByUserId(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserBalanceFilterInput
  $limit: Int
  $nextToken: String
) {
  balanceByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        email
        profilePictureUrl
        profileBannerUrl
        description
        type
        createdAt
        updatedAt
        __typename
      }
      deposit {
        value
        currency
        __typename
      }
      balance {
        value
        currency
        __typename
      }
      currency
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BalanceByUserIdQueryVariables,
  APITypes.BalanceByUserIdQuery
>;
export const getCandyMachineNFTOne = /* GraphQL */ `query GetCandyMachineNFTOne($id: ID!) {
  getCandyMachineNFTOne(id: $id) {
    id
    candyMachineId
    collectionId
    mintingType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCandyMachineNFTOneQueryVariables,
  APITypes.GetCandyMachineNFTOneQuery
>;
export const listCandyMachineNFTOnes = /* GraphQL */ `query ListCandyMachineNFTOnes(
  $filter: ModelCandyMachineNFTOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listCandyMachineNFTOnes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      candyMachineId
      collectionId
      mintingType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCandyMachineNFTOnesQueryVariables,
  APITypes.ListCandyMachineNFTOnesQuery
>;
export const candyByAddress = /* GraphQL */ `query CandyByAddress(
  $candyMachineId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCandyMachineNFTOneFilterInput
  $limit: Int
  $nextToken: String
) {
  candyByAddress(
    candyMachineId: $candyMachineId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      candyMachineId
      collectionId
      mintingType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CandyByAddressQueryVariables,
  APITypes.CandyByAddressQuery
>;
export const candyByCollection = /* GraphQL */ `query CandyByCollection(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCandyMachineNFTOneFilterInput
  $limit: Int
  $nextToken: String
) {
  candyByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      candyMachineId
      collectionId
      mintingType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CandyByCollectionQueryVariables,
  APITypes.CandyByCollectionQuery
>;
export const searchCandyMachineNFTOnes = /* GraphQL */ `query SearchCandyMachineNFTOnes(
  $filter: SearchableCandyMachineNFTOneFilterInput
  $sort: [SearchableCandyMachineNFTOneSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCandyMachineNFTOneAggregationInput]
) {
  searchCandyMachineNFTOnes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      candyMachineId
      collectionId
      mintingType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCandyMachineNFTOnesQueryVariables,
  APITypes.SearchCandyMachineNFTOnesQuery
>;
export const getCollectionStatusTtl = /* GraphQL */ `query GetCollectionStatusTtl($id: ID!) {
  getCollectionStatusTtl(id: $id) {
    id
    collectionId
    status
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCollectionStatusTtlQueryVariables,
  APITypes.GetCollectionStatusTtlQuery
>;
export const listCollectionStatusTtls = /* GraphQL */ `query ListCollectionStatusTtls(
  $filter: ModelCollectionStatusTtlFilterInput
  $limit: Int
  $nextToken: String
) {
  listCollectionStatusTtls(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCollectionStatusTtlsQueryVariables,
  APITypes.ListCollectionStatusTtlsQuery
>;
export const collectioStatusTtlByCollection = /* GraphQL */ `query CollectioStatusTtlByCollection(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCollectionStatusTtlFilterInput
  $limit: Int
  $nextToken: String
) {
  collectioStatusTtlByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CollectioStatusTtlByCollectionQueryVariables,
  APITypes.CollectioStatusTtlByCollectionQuery
>;
export const getRemindLaunchpad = /* GraphQL */ `query GetRemindLaunchpad($id: ID!) {
  getRemindLaunchpad(id: $id) {
    id
    collectionId
    userId
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRemindLaunchpadQueryVariables,
  APITypes.GetRemindLaunchpadQuery
>;
export const listRemindLaunchpads = /* GraphQL */ `query ListRemindLaunchpads(
  $filter: ModelRemindLaunchpadFilterInput
  $limit: Int
  $nextToken: String
) {
  listRemindLaunchpads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      collectionId
      userId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRemindLaunchpadsQueryVariables,
  APITypes.ListRemindLaunchpadsQuery
>;
export const remindLaunchpadByCollection = /* GraphQL */ `query RemindLaunchpadByCollection(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRemindLaunchpadFilterInput
  $limit: Int
  $nextToken: String
) {
  remindLaunchpadByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      userId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RemindLaunchpadByCollectionQueryVariables,
  APITypes.RemindLaunchpadByCollectionQuery
>;
export const remindLaunchpadByUser = /* GraphQL */ `query RemindLaunchpadByUser(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRemindLaunchpadFilterInput
  $limit: Int
  $nextToken: String
) {
  remindLaunchpadByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      userId
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RemindLaunchpadByUserQueryVariables,
  APITypes.RemindLaunchpadByUserQuery
>;
export const getCandyMachineStatus = /* GraphQL */ `query GetCandyMachineStatus($id: ID!) {
  getCandyMachineStatus(id: $id) {
    id
    collectionId
    status
    startDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCandyMachineStatusQueryVariables,
  APITypes.GetCandyMachineStatusQuery
>;
export const listCandyMachineStatuses = /* GraphQL */ `query ListCandyMachineStatuses(
  $filter: ModelCandyMachineStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listCandyMachineStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      startDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCandyMachineStatusesQueryVariables,
  APITypes.ListCandyMachineStatusesQuery
>;
export const candyMachineStatusByCollection = /* GraphQL */ `query CandyMachineStatusByCollection(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCandyMachineStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  candyMachineStatusByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      startDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CandyMachineStatusByCollectionQueryVariables,
  APITypes.CandyMachineStatusByCollectionQuery
>;
export const candyMachineStatusByStatusAndCreatedAt = /* GraphQL */ `query CandyMachineStatusByStatusAndCreatedAt(
  $status: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCandyMachineStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  candyMachineStatusByStatusAndCreatedAt(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      startDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CandyMachineStatusByStatusAndCreatedAtQueryVariables,
  APITypes.CandyMachineStatusByStatusAndCreatedAtQuery
>;
export const candyMachineStatusByStatusAndStartDate = /* GraphQL */ `query CandyMachineStatusByStatusAndStartDate(
  $status: String!
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCandyMachineStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  candyMachineStatusByStatusAndStartDate(
    status: $status
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      startDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CandyMachineStatusByStatusAndStartDateQueryVariables,
  APITypes.CandyMachineStatusByStatusAndStartDateQuery
>;
export const getNumberOfMinting = /* GraphQL */ `query GetNumberOfMinting($id: ID!) {
  getNumberOfMinting(id: $id) {
    id
    collectionId
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNumberOfMintingQueryVariables,
  APITypes.GetNumberOfMintingQuery
>;
export const listNumberOfMintings = /* GraphQL */ `query ListNumberOfMintings(
  $filter: ModelNumberOfMintingFilterInput
  $limit: Int
  $nextToken: String
) {
  listNumberOfMintings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      collectionId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNumberOfMintingsQueryVariables,
  APITypes.ListNumberOfMintingsQuery
>;
export const NumberOfMintingByCollection = /* GraphQL */ `query NumberOfMintingByCollection(
  $collectionId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelNumberOfMintingFilterInput
  $limit: Int
  $nextToken: String
) {
  NumberOfMintingByCollection(
    collectionId: $collectionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      collectionId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NumberOfMintingByCollectionQueryVariables,
  APITypes.NumberOfMintingByCollectionQuery
>;
export const getItemListingTtl = /* GraphQL */ `query GetItemListingTtl($id: ID!) {
  getItemListingTtl(id: $id) {
    id
    itemId
    currency
    price
    status
    expiredDate
    ttl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetItemListingTtlQueryVariables,
  APITypes.GetItemListingTtlQuery
>;
export const listItemListingTtls = /* GraphQL */ `query ListItemListingTtls(
  $filter: ModelItemListingTtlFilterInput
  $limit: Int
  $nextToken: String
) {
  listItemListingTtls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      itemId
      currency
      price
      status
      expiredDate
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListItemListingTtlsQueryVariables,
  APITypes.ListItemListingTtlsQuery
>;
export const itemListingByItemId = /* GraphQL */ `query ItemListingByItemId(
  $itemId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelItemListingTtlFilterInput
  $limit: Int
  $nextToken: String
) {
  itemListingByItemId(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      itemId
      currency
      price
      status
      expiredDate
      ttl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemListingByItemIdQueryVariables,
  APITypes.ItemListingByItemIdQuery
>;
export const getRedeemCode = /* GraphQL */ `query GetRedeemCode($code: String!) {
  getRedeemCode(code: $code) {
    id
    code
    amount
    isActive
    action
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRedeemCodeQueryVariables,
  APITypes.GetRedeemCodeQuery
>;
export const getAppConfig = /* GraphQL */ `query GetAppConfig($key: String) {
  getAppConfig(key: $key) {
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppConfigQueryVariables,
  APITypes.GetAppConfigQuery
>;
export const getOrderDetail = /* GraphQL */ `query GetOrderDetail($orderId: String!) {
  getOrderDetail(orderId: $orderId) {
    orderId
    userId
    itemDetails {
      id
      name
      imageUrl
      price {
        amount
        currency
        __typename
      }
      __typename
    }
    tax {
      taxName
      amount
      currency
      __typename
    }
    status
    action
    totalIDR
    totalSOL
    expiredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDetailQueryVariables,
  APITypes.GetOrderDetailQuery
>;
export const getPaymentDetail = /* GraphQL */ `query GetPaymentDetail($paymentId: String!) {
  getPaymentDetail(paymentId: $paymentId) {
    paymentId
    orderId
    userId
    amount
    currency
    paymentType
    status
    expiredAt
    createdAt
    updatedAt
    orderType
    itemDetails {
      id
      name
      imageUrl
      price {
        amount
        currency
        __typename
      }
      __typename
    }
    tax {
      taxName
      amount
      currency
      __typename
    }
    actions {
      name
      method
      url
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentDetailQueryVariables,
  APITypes.GetPaymentDetailQuery
>;
export const getRateSolanaIDR = /* GraphQL */ `query GetRateSolanaIDR {
  getRateSolanaIDR {
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRateSolanaIDRQueryVariables,
  APITypes.GetRateSolanaIDRQuery
>;
