/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-southeast-1',
  aws_appsync_graphqlEndpoint:
    'https://k6h4brmvnfajrln5rnv6jx2zku.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-bm4rxsywfbcdrmx36dhai5fwti',
  aws_cognito_identity_pool_id:
    'ap-southeast-1:0f91f831-9f39-4a88-b9f0-34d79442fa5d',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: 'ap-southeast-1_OuxYW7lM1',
  aws_user_pools_web_client_id: '43g07g3h7mpblt7gbdcb8hb5ua',
  oauth: {
    domain: 'nftone-main.auth.ap-southeast-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://nftone.io/,http://localhost:3000/',
    redirectSignOut: 'https://nftone.io/,http://localhost:3000/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '6',
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['PHONE_NUMBER'],
  aws_user_files_s3_bucket: 'nftone94608-main',
  aws_user_files_s3_bucket_region: 'ap-southeast-1',
};

export default awsmobile;
