/* eslint-disable import/no-anonymous-default-export */
import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
  container: {
    rounded: "md",
  },
};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      color: "white",
      bgGradient: "linear(to-br, main.magenta, main.purple, main.blue)",
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  baseStyle,
  variants,
};
