/* eslint-disable import/no-anonymous-default-export */
import { switchAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    track: {
      bg: mode("gray.300", "main.spaceCadet")(props),
      _checked: {
        bgGradient: "linear(to-br, main.magenta, main.purple, main.blue)",
      },
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
