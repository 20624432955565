/* eslint-disable import/no-anonymous-default-export */
import { radioAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
  container: {
    rounded: "md",
  },
};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;

  return {
    container: {},
    control: {
      borderColor: mode("gray.400", "gray.200")(props),
      position: "relative",
      _hover: {
        borderColor: "main.purple",
        _before: {
          borderColor: "main.blue",
          bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
          w: "80%",
          h: "80%",
          border: "none",
          position: "absolute",
        },
      },
      _checked: {
        borderColor: "main.purple",
        bg: "transparent",
        transform: "scale(1.2)",
        p: 2,
        _before: {
          borderColor: "main.blue",
          bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
          w: "80%",
          h: "80%",
          border: "none",
          position: "absolute",
        },
      },
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  baseStyle,
  variants,
};
