/* eslint-disable import/no-anonymous-default-export */
import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode("red.500", "red.300")(props),
  };
}

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: mode("gray.200", "main.spaceCadet")(props),
      _placeholder: {
        color: mode("gray.400", "gray.500")(props),
      },
      _invalid: {
        boxShadow: `inset 0 0 0 2px ${getColor(theme, ec)}`,
      },
      _disabled: {
        opacity: 0.6,
        cursor: "not-allowed",
      },
    },
    addon: {
      bg: mode("gray.300", "spaceCadet.800")(props),
    },
    element: {
      bg: mode("gray.300", "spaceCadet.800")(props),
    },
  };
};

const variantImage: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: mode("gray.100", "main.spaceCadet")(props),
      width: "200px",
      height: "200px",
      appearance: "none",
    },
  };
};

const variants = {
  main: variantMain,
  image: variantImage,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
