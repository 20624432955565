/* eslint-disable import/no-anonymous-default-export */
import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyleList: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleItem: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleGroupTitle: SystemStyleObject = {};

const baseStyleCommand: SystemStyleObject = {};

const baseStyleDivider: SystemStyleObject = {};

const baseStyleButton: SystemStyleObject = {};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    item: {
      bg: mode("gray.50", "main.newDarkBlue")(props),
      _hover: {
        bg: mode("blackAlpha.50", "whiteAlpha.50")(props),
      },
    },
    list: {
      bg: mode("gray.50", "main.newDarkBlue")(props),
      shadow: "md",
    },
  };
};

const variantAsSelect: PartsStyleFunction<typeof parts> = (props) => {
  return {
    button: {
      bg: mode("gray.50", "main.spaceCadet")(props),
      width: "100%",
      textAlign: "left",
      paddingX: 3,
      paddingY: 2,
      borderRadius: 6,
    },
    item: {
      display: "flex",
      gap: 4,
      minW: "100%",
      bg: mode("gray.50", "main.spaceCadet")(props),
      _hover: {
        bg: mode("blackAlpha.50", "whiteAlpha.50")(props),
      },
    },
    list: {
      width: "100%",
      bg: mode("gray.50", "main.spaceCadet")(props),
      shadow: "md!important",
    },
  };
};

const variants = {
  main: variantMain,
  "as-select": variantAsSelect,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
