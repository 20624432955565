/* eslint-disable import/no-anonymous-default-export */
import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode("red.500", "red.300")(props),
  };
}

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: mode("gray.200", "main.spaceCadet")(props),
      _invalid: {
        boxShadow: `inset 0 0 0 2px ${getColor(theme, ec)}`,
      },
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
