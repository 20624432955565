/* eslint-disable import/no-anonymous-default-export */
import type {
  SystemStyleObject,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
  _loading: {
    cursor: "wait",
  },
  _hover: {
    color: "main.blue",
  },
};

const variantPurple: SystemStyleObject = {
  bgColor: "main.purple",
  color: "white",
  _hover: {
    bgColor: "#C51FA0",
    color: "white",
  },
};

const variantPurpleOutline: SystemStyleObject = {
  bgColor: "transparent",
  color: "main.purple",
  border: "1px solid",
  borderColor: "main.purple",
  _hover: {
    bgColor: "whiteAlpha.200",
    borderColor: "#C51FA0",
    color: "#C51FA0",
  },
};

const variantMagenta: SystemStyleObject = {
  bgColor: "main.magenta",
  color: "white",
};

const variantGradient: SystemStyleFunction = () => {
  return {
    bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
    color: mode("gray.50", "gray.50"),
    bgSize: "150% 100%",
    transition: "all .25s ease-in-out",
    _hover: {
      color: "gray.50",
      bgPosition: "50% 0",
      _disabled: {
        bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
      },
    },
    _loading: {
      cursor: "wait",
    },
  };
};

const variantOutlineGradient: SystemStyleFunction = (props) => {
  return {
    display: "flex",
    rounded: "md",
    bg: mode("gray.50", "main.darkblue")(props),
    color: mode("gray.800", "gray.50")(props),
    transition: "all .25s ease-in-out",
    _hover: {
      color: "gray.50",
      bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
      transition: "all .25s ease-in-out",
      shadow: "md",
    },
    _focus: {
      color: mode("gray.800", "gray.50")(props),
    },
    _after: {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      margin: "-2px",
      borderRadius: "inherit",
      bgGradient: "linear(to-br, main.magenta, main.purple ,main.blue)",
    },
  };
};

const variantInput: SystemStyleFunction = (props) => {
  return {
    bg: mode("gray.200", "main.spaceCadet")(props),
    color: mode("gray.700", "gray.100")(props),
    fontWeight: "regular",
    _hover: {
      color: mode("gray.700", "gray.100")(props),
    },
    _active: {
      color: mode("gray.700", "gray.100")(props),
    },
  };
};

const variantGhost: SystemStyleFunction = (props) => {
  return {
    bg: "transparent",
    color: mode("gray.700", "gray.100")(props),
    _hover: {
      bg: mode("blackAlpha.50", "whiteAlpha.100")(props),
      color: mode("gray.700", "gray.100")(props),
    },
    _active: {
      bg: mode("blackAlpha.50", "whiteAlpha.100")(props),
      color: mode("gray.700", "gray.100")(props),
    },
  };
};

const variants = {
  magenta: variantMagenta,
  purple: variantPurple,
  "purple-outline": variantPurpleOutline,
  input: variantInput,
  gradient: variantGradient,
  "gradient-outline": variantOutlineGradient,
  "ghost-main": variantGhost,
};

export default {
  baseStyle,
  variants,
};
