/* eslint-disable import/no-anonymous-default-export */
import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleObject,
  SystemStyleObject,
  SystemStyleFunction,
  PartsStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyleContainer: SystemStyleObject = {
  borderTopWidth: 0,
  borderColor: "inherit",
  overflow: "visible",
  _last: {
    borderBottomWidth: 0,
  },
};

const baseStyleButton: SystemStyleObject = {
  fontWeight: "semibold",
  bg: "transparent",
  borderRadius: "md",
  shadow: "md",
};

const baseStylePanel: SystemStyleFunction = (props) => {
  return {
    borderWidth: "1px",
    borderColor: "alpha.purple",
    p: 0,
    mt: 4,
    shadow: "md",
  };
};

const baseStyleIcon: SystemStyleObject = {
  fontSize: "1.5em",
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: {},
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel(props),
  icon: baseStyleIcon,
});

const variantFilterContainer: SystemStyleObject = {};

const variantFilterButton: SystemStyleObject = {
  fontWeight: "semibold",
  bg: "transparent",
  borderRadius: "md",
  shadow: "none",
  mb: 4,
};

const variantFilterPanel: SystemStyleFunction = (props) => {
  return {
    shadow: "none",
    border: 0,
    p: 0,
    mb: 4,
  };
};

const variantFilter: PartsStyleFunction<typeof parts> = (props) => ({
  root: {},
  container: variantFilterContainer,
  button: variantFilterButton,
  panel: variantFilterPanel(props),
  icon: baseStyleIcon,
});

const variants = {
  filter: variantFilter,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
