/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-southeast-1',
  aws_appsync_graphqlEndpoint:
    'https://farasci46rdflfou66drk4jjv4.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-egh76fldj5gbdgrgg2erntyeoa',
  aws_cognito_identity_pool_id:
    'ap-southeast-1:02979a8e-8dc6-41ad-b5d3-51a49114cb2a',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: 'ap-southeast-1_0UWfmkI2x',
  aws_user_pools_web_client_id: '5trha30a8gh5rb65t3isg2oo07',
  oauth: {
    domain: 'nftone-dev.auth.ap-southeast-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://nftone.io/,http://localhost:3000/',
    redirectSignOut: 'https://nftone.io/,http://localhost:3000/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '6',
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['PHONE_NUMBER'],
  aws_user_files_s3_bucket: 'nftone132606-dev',
  aws_user_files_s3_bucket_region: 'ap-southeast-1',
  aws_mobile_analytics_app_id: '5e91ea141a3d4172aaeb9fddb78e7523',
  aws_mobile_analytics_app_region: 'ap-southeast-1',
};

export default awsmobile;
