/* eslint-disable import/no-anonymous-default-export */
import type {
  SystemStyleObject,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode("red.500", "red.300")(props),
  };
}

const variantMain: SystemStyleFunction = (props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    bg: mode("gray.200", "main.spaceCadet")(props),
    _placeholder: {
      color: mode("gray.400", "gray.500")(props),
    },
    _invalid: {
      boxShadow: `inset 0 0 0 2px ${getColor(theme, ec)}`,
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  baseStyle,
  variants: variants,
};
