/* eslint-disable import/no-anonymous-default-export */
import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    closeButton: {
      top: 4,
      right: 4,
    },
    body: {
      bg: mode("gray.100", "main.spaceCadet")(props),
      px: 0,
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
