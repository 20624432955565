/* eslint-disable import/no-anonymous-default-export */
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {};

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  return {
    dialog: {
      my: 0,
      mx: "1px",
      h: { base: "full", md: "auto" },
      maxH: "100%",
      bgGradient: "linear(to-br, main.magenta, main.purple ,main.cyan)",
      rounded: { base: "none", md: "xl" },
      px: "1px",
      position: "relative",
      _after: {
        content: "''",
        bg: mode("gray.100", "gradient.purpleDark")(props),
        w: "calc(100% - 2px)",
        h: "calc(100% - 2px)",
        zIndex: -1,
        top: "1px",
        left: "1px",
        rounded: { base: "none", md: "xl" },
        position: "absolute",
      },
    },
    header: {
      textAlign: "left",
      bgGradient: "linear(to-br, main.magenta, main.purple ,main.cyan)",
      bgClip: "text",
      fontSize: "2xl",
      borderBottomWidth: "1px",
      borderColor: "main.magenta",
      py: 8,
      px: 12,
    },
    closeButton: {
      top: 9,
      right: 12,
      p: 4,
      fontSize: "9px",
      bgGradient: "linear(to-br, main.magenta, main.purple ,main.cyan)",
      rounded: "full",
      zIndex: 1,
      _after: {
        content: "''",
        bg: mode("gray.100", "gradient.purpleDark")(props),
        w: "calc(100% - 2px)",
        h: "calc(100% - 2px)",
        zIndex: -1,
        top: "1px",
        left: "1px",
        rounded: "full",
        position: "absolute",
      },
      _hover: {
        bgGradient: "linear(to-br, main.magenta, main.purple ,main.cyan)",
        _after: {
          bgGradient: "linear(to-br, main.magenta, main.purple ,main.cyan)",
        },
      },
    },
    body: {
      bg: mode("gray.100", "gradient.purpleDark")(props),
      pt: 8,
      px: 12,
    },
    footer: {
      pb: 8,
      px: 12,
    },
  };
};

const variants = {
  main: variantMain,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
