import { API, Auth, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  GetAppConfigQuery,
  GetRateSolanaIDRQuery,
  GetUserProfileQuery,
  UserProfile,
} from 'src/API';
import {
  getAppConfig,
  getRateSolanaIDR,
  getUserProfile,
} from 'src/graphql/queries';

type parameterStoreContextType = {
  parameterStore: string | null;
  connectionUrl: string | null;
  midtransClientKey: string | null;
  idrBalance: number | null;
  userProfile: UserProfile | null;
  userProfileLoaded: boolean;
  tanggalBagiNft: string | null;
  gridSize: 'small' | 'medium';
  setGridSize: Dispatch<SetStateAction<'small' | 'medium'>> | undefined;
  refreshParameterStore: () => void;
  refreshIdrPrice: () => void;
  refreshUserProfile: () => void;
};

const parameterStoreContextDefault: parameterStoreContextType = {
  parameterStore: null,
  connectionUrl: null,
  midtransClientKey: null,
  idrBalance: null,
  userProfile: null,
  userProfileLoaded: false,
  tanggalBagiNft: null,
  gridSize: 'medium',
  setGridSize: undefined,
  refreshParameterStore: () => {},
  refreshIdrPrice: () => {},
  refreshUserProfile: () => {},
};

const ParameterStoreContext = createContext<parameterStoreContextType>(
  parameterStoreContextDefault
);

export const useParameterStore = () => {
  return useContext(ParameterStoreContext);
};

export const ParameterStoreProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [parameterStoreData, setParameterStoreData] = useState<string | null>(
    null
  );
  const [connectionUrlData, setConnectionUrlData] = useState<string | null>(
    null
  );
  const [idrBalance, setIdrBalance] = useState<number | null>(null);
  const [midtransClientKey, setMidtransCmidtransClientKey] = useState<
    string | null
  >(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [userProfileLoaded, setUserProfileLoaded] = useState<boolean>(false);
  const [tanggalBagiNFT, setTanggalBagiNFT] = useState<string | null>(null);
  const [gridSize, setGridSize] = useState<'medium' | 'small'>('medium');

  const getParameterStore = useCallback(async () => {
    await API.graphql<GraphQLQuery<GetAppConfigQuery>>(
      graphqlOperation(getAppConfig, { key: 'nft.auctionHouseAddress' })
    )
      .then((res) => {
        if (res.data?.getAppConfig?.value) {
          setParameterStoreData(JSON.parse(res.data.getAppConfig.value));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const getConnection = useCallback(async () => {
    await API.graphql<GraphQLQuery<GetAppConfigQuery>>(
      graphqlOperation(getAppConfig, { key: 'nft.urlConnection' })
    )
      .then((res) => {
        if (res.data?.getAppConfig?.value) {
          setConnectionUrlData(JSON.parse(res.data.getAppConfig.value));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const getSolIdr = useCallback(async () => {
    try {
      const getRate = await API.graphql<GraphQLQuery<GetRateSolanaIDRQuery>>({
        query: getRateSolanaIDR,
      });

      if (getRate.data?.getRateSolanaIDR) {
        setIdrBalance(getRate.data.getRateSolanaIDR.value);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getMidtransClientKey = useCallback(async () => {
    await API.graphql<GraphQLQuery<GetAppConfigQuery>>(
      graphqlOperation(getAppConfig, { key: 'midtrans.clientKey' })
    )
      .then((res) => {
        if (res.data?.getAppConfig?.value) {
          setMidtransCmidtransClientKey(
            JSON.parse(res.data.getAppConfig.value)
          );
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const getAuthencicatedUserProfile = useCallback(async () => {
    let user: string | null = null;
    setUserProfileLoaded(false);

    try {
      const userData = await Auth.currentAuthenticatedUser();
      const { username } = userData;
      user = username;
    } catch (error) {
      setUserProfile(null);
    }

    if (user) {
      try {
        const profile = await API.graphql<GraphQLQuery<GetUserProfileQuery>>(
          graphqlOperation(getUserProfile, { id: user })
        );

        if (profile.data?.getUserProfile) {
          setUserProfile(profile.data.getUserProfile);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setUserProfile(null);
    }

    setUserProfileLoaded(true);
  }, []);

  const getTanggalBagiNFT = useCallback(async () => {
    await API.graphql<GraphQLQuery<GetAppConfigQuery>>(
      graphqlOperation(getAppConfig, { key: 'nft.tanggalBagiNFT' })
    )
      .then((res) => {
        if (res.data?.getAppConfig?.value) {
          setTanggalBagiNFT(JSON.parse(res.data.getAppConfig.value));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getConnection();
    getParameterStore();
    getSolIdr();
    getMidtransClientKey();
    getTanggalBagiNFT();
  }, [
    getParameterStore,
    getConnection,
    getSolIdr,
    getMidtransClientKey,
    getTanggalBagiNFT,
  ]);

  useEffect(() => {
    getAuthencicatedUserProfile();
  }, [getAuthencicatedUserProfile]);

  useEffect(() => {
    // Sync date every 1 minute
    const timer = setInterval(() => {
      getSolIdr();
    }, 30 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [getSolIdr]);

  const value: parameterStoreContextType = {
    parameterStore: parameterStoreData,
    connectionUrl: connectionUrlData,
    midtransClientKey: midtransClientKey,
    idrBalance: idrBalance,
    userProfile: userProfile,
    userProfileLoaded: userProfileLoaded,
    tanggalBagiNft: tanggalBagiNFT,
    gridSize: gridSize,
    setGridSize: setGridSize,
    refreshIdrPrice: () => getSolIdr(),
    refreshParameterStore: () => getParameterStore(),
    refreshUserProfile: () => getAuthencicatedUserProfile(),
  };

  return (
    <ParameterStoreContext.Provider value={value}>
      {children}
    </ParameterStoreContext.Provider>
  );
};
