/* eslint-disable import/no-anonymous-default-export */
import type {
  SystemStyleObject,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = (props) => {
  return {
    textDecoration: "none",
    color: mode("gray.900", "gray.50")(props),
    _hover: {
      color: "main.blue",
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  };
};

const variantPurpleGradient: SystemStyleObject = {
  bgGradient: "linear(to-r, main.purple, main.blue)",
  textDecorationColor: "main.purple",
  textUnderlineOffset: 4,
  backgroundClip: "text",
  textFillColor: "transparent",
  _hover: {
    textUnderlineOffset: 4,
    textDecoration: "underline",
    textDecorationColor: "main.purple",
  },
  _focus: {
    boxShadow: "none",
  },
};

const variants = {
  purpleGradient: variantPurpleGradient,
};

export default {
  baseStyle,
  variants,
};
