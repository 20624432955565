/* eslint-disable import/no-anonymous-default-export */
import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyleRoot: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleTab: SystemStyleObject = {
  _focus: {
    boxShadow: "none",
  },
};

const baseStyleTablist: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleTabpanel: SystemStyleObject = {};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab,
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
});

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === "vertical";
  const borderProp =
    orientation === "vertical" ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";

  return {
    root: {
      [borderProp]: "1px solid",
      borderColor: "inherit",
    },
    tablist: {},
    tab: {
      position: "relative",
      [borderProp]: "2px solid",
      borderImageSlice: 1,
      borderColor: "transparent",
      _disabled: {
        _active: { bg: "none" },
      },
    },
  };
};

const variantProgress: PartsStyleFunction<typeof parts> = (props) => {
  return {
    tablist: {
      gap: 4,
      mb: 12,
    },
    tab: {
      borderTopWidth: "10px",
      borderColor: "main.magenta",
      textAlign: "left",
      justifyContent: "flex-start",
      px: 0,
      _disabled: {
        borderColor: mode("gray.300", "main.spaceCadet")(props),
      },
    },
    tabpanel: {
      p: 0,
    },
  };
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  main: variantMain,
  progress: variantProgress,
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
};
