/* eslint-disable import/no-anonymous-default-export */
import { selectAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { mergeWith } from "@chakra-ui/utils";
import { mode } from "@chakra-ui/theme-tools";
import Input from "./input";

const baseStyleField: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleIcon: SystemStyleObject = {};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
});

export default {
  parts: parts.keys,
  baseStyle,
  variants: Input.variants,
};
