import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import Accordion from './components/accordion';
import Button from './components/button';
import Input from './components/input';
import Textarea from './components/textarea';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import Tabs from './components/tabs';
import Table from './components/table';
import Select from './components/select';
import NumberInput from './components/number-input';
import Alert from './components/alert';
import Skeleton from './components/skeleton';
import Radio from './components/radio';
import Checkbox from './components/checkbox';
import Popover from './components/popover';
import Switch from './components/switch';
import Progress from './components/progress';
import Drawer from './components/drawer';

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  fonts: {
    body: `Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    heading: `Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.50', 'main.darkblue')(props),
      },
      ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
        overflow: 'initial !important',
      },
    }),
  },
  colors: {
    main: {
      gold: '#d7c62d',
      blue: '#3081ed',
      darkblue: '#040428',
      darkSlateBlue: '#484491',
      borderBlue: '#151249',
      purple: '#9b51e0',
      magenta: '#c51fa0',
      spaceCadet: '#16274E',
      newDarkBlue: 'rgba(12, 20, 56, 1)',
      orange: '#E2922E',
      cyan: '#15ADDD',
      darkPurple: '#15115A',
      softPurple: '#E7E6FC',
      red: '#FF6565',
    },
    spaceCadet: {
      50: '#e7f6ff',
      100: '#c2daf1',
      200: '#9bbee3',
      300: '#74a0d8',
      400: '#4e81cc',
      500: '#3462b2',
      600: '#28498b',
      700: '#1c3264',
      800: '#0e213e',
      900: '#030d1a',
    },
    magenta: {
      50: '#ffe6fc',
      100: '#f9bbea',
      200: '#f090da',
      300: '#e864cb',
      400: '#e039bb',
      500: '#c61fa1',
      600: '#9b167d',
      700: '#6f0e5a',
      800: '#440538',
      900: '#1b0016',
    },
    darkPurple: {
      50: '#ebe9ff',
      100: '#c2bff5',
      200: '#9994eb',
      300: '#7069e2',
      400: '#483fd9',
      500: '#2f25bf',
      600: '#241c95',
      700: '#19146b',
      800: '#0e0c42',
      900: '#05031a',
    },
    gradient: {
      main: 'linear-gradient(to bottom, #c51fa0, #9b51e0, #3081ed)',
      mainToBR: 'linear-gradient(to bottom right, #c51fa0, #9b51e0, #3081ed)',
      purpleRainbow:
        'linear-gradient(180deg, rgba(251, 55, 255, 1) 0%, rgba(155, 111, 238, 0) 35%, rgba(123, 127, 234, 0) 65%, rgba(27, 178, 222, 1) 100%)',
      purpleDark:
        'linear-gradient(170deg,rgba(12, 20, 56, 1) 0%, rgba(11, 12, 47, 1) 100%)',
      purpleToBlueDark:
        'linear-gradient(169.44deg, rgba(251, 55, 255, 1) 0%,rgba(11, 12, 47, 1) 90%)',
      purpleToBlueLight:
        'linear-gradient(169.44deg, rgba(251, 55, 255, 1) -100%, rgba(255, 255, 255, 0.92) 90%)',
      blue: 'linear-gradient(169.44deg, rgba(255,255,255, 0) 0%, rgba(255,255,255, 0) 25%, rgba(255,255,255, 0) 50%, rgba(27, 178, 222, 1) 100%)',
    },
    alpha: {
      white: 'rgba(255, 255, 255, 0.85)',
      red: 'rgb(234, 59, 59, 0.2)',
      purple: 'rgb(196 181 253/.3)',
      blue: 'rgba(48, 129, 237, 0.05)',
    },
  },
  shadows: {
    'outline-purple': '0 0 0 3px #9b51e0',
    'glow-blue': '0 0 0 1px #15ADDD, 0 0 8px 1px #15ADDD',
    cardShadowLight: '0px 8px 16px rgba(0, 0, 0, 0.08)',
    cardShadowHover: '0px 16px 24px rgba(0, 0, 0, 0.1)',
  },
  sizes: {
    '4-12': '33.333333%',
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  layerStyles: {
    purpleGradient: {
      textDecorationColor: 'main.purple',
    },
  },
  components: {
    Accordion,
    Button,
    Input,
    Textarea,
    Link,
    Menu,
    Modal,
    Tabs,
    Select,
    NumberInput,
    Alert,
    Skeleton,
    Radio,
    Checkbox,
    Popover,
    Switch,
    Progress,
    Drawer,
    Table,
  },
});

export default theme;
