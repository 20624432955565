/* eslint-disable import/no-anonymous-default-export */
import { cssVar } from "@chakra-ui/react";
import type { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const baseStyle: SystemStyleFunction = (props) => {
  var defaultStartColor = mode("gray.100", "rgba(12, 20, 56, 1)")(props);
  var defaultEndColor = mode("gray.400", "#16274E")(props);

  return {
    opacity: 0.7,
    background: defaultEndColor,
    _light: {
      [$startColor.variable]: defaultStartColor,
      [$endColor.variable]: defaultEndColor,
    },
    _dark: {
      [$startColor.variable]: defaultStartColor,
      [$endColor.variable]: defaultEndColor,
    },
  };
};

export default {
  baseStyle,
};
